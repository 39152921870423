
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';

.truncate-toggle-button {
 background-color: transparent;
 border: 0;
 color: $primary-text;
 display: inline;
 font-size: 16px;
 white-space-collapse: collapse;
}