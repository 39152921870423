
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';
@import '../../style/buttons.scss';

.topics-links-wrapper {
 margin-top: 78px;
 
 .topic-link-title {
  @include roboto-medium;
  font-size: 18px;
  color: $primary-text;
  margin-bottom: 0;
 }

 .list-link-item {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
 }
}